import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SupportwebwsService } from 'src/app/services/supportwebws.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { FacadeService } from 'src/app/services/facade.service';
import { DatePipe } from '@angular/common';
import { addIcons } from 'ionicons';
import { Storage } from '@ionic/storage';
import { stat } from 'fs';
import { get } from 'http';
import { AuthenticationService } from 'src/app/services/authentication.service';

addIcons({
  'md-excel-outline': 'assets/md-excel-outline.svg',
  'excel': 'assets/excel.png'
});
@Component({
  selector: 'app-incidentlist',
  templateUrl: './incidentlist.component.html',
  styleUrls: ['./incidentlist.component.scss'],
})
export class IncidentlistComponent implements OnInit {
  itemPath: string = "assets/excel48.png";

  incidents: any;
  incidentsBackup: any;

  // notifications: any;

  products: any;
  contracts: any;
  features: any;
  priorities: any;
  categories: any;
  sprints: any;
  solvers: any;
  contacts: any;
  showRefNo: boolean = false;

  public searchControl: FormControl;
  searching: any = false;
  descendingCreatedOn: boolean = false;
  descendingPriority: boolean = false;
  descendingCategory: boolean = false;
  descendingSprint: boolean = false;
  descendingState: boolean = false;
  descendingContract: boolean = false;
  descendingProduct: boolean = false;
  descendingFeature: boolean = false;
  descendingContact: boolean = false;
  descendingSolver: boolean = false;
  showCompleted: boolean = false;
  lastClickedIncident: string;
  expand: boolean = false;
  selectedView: string = "open";
  selectedState;
  selectedContract;
  selectedProduct;
  selectedFeature;
  selectedPriority;
  selectedCategory;
  selectedSprint;
  selectedSolver;
  selectedContact;
  approvingIncident;
  approvingOrRejecting: boolean = true;
  filters: any = {};
  // userPrivileges: any;

  activeLoaded = false;
  inactiveLoaded = false;
  loading = false;
  username: string = "";

  @ViewChild('searchText') searchText;
  incidentListCookies: any;
  waitingreason: number = -1;
  filtersShown: boolean = false;

  constructor(private router: Router, private supportwebws: SupportwebwsService, private authService: AuthenticationService,
    private facadeService: FacadeService, private changeRef: ChangeDetectorRef, private storage: Storage) {
    this.searchControl = new FormControl();
    // this.userPrivileges = { CreateSupportRequest: false, ApproveSolutionTime: false, ApprovePublishingToLive: false, ApproveInvoicing: false };
  }

  async ngOnInit() {
    this.facadeService.menuIndexSelected = 1;

    this.storage.get("incidentlistcookies").then(x => {

      if (x) {
        this.incidentListCookies = x;
        this.expand = x.expand;
        this.filtersShown = x.filtersShown;
      }
      else {
        this.expand = false;
        this.filtersShown = false;
        this.incidentListCookies = {
          expand: false,
          filtersShown: false
        }
      }

      if (this.filtersShown)
        this.openFilters();
    });

    // this.authService.getPrivileges().subscribe(p => {
    //   this.userPrivileges = p;
    // });


    // this.userPrivileges = this.authService.privileges ? this.authService.privileges : await this.getPrivileges();

    this.searchControl.valueChanges
      .pipe(debounceTime(700))
      .subscribe(search => {
        this.searching = false;
        this.searchItems(search);
      });

    // this.loadData();
    // this.resetFilters();
    // this.supportwebws.getNotificationsForContact(this.authService.privileges.ContactId).subscribe(result => {
    //   this.facadeService.helperService.notifications = result;
    this.loadDataNew();
    // });
  }

  async getPrivileges() {
    return await this.authService.getPrivileges().toPromise();
  }

  resetFilters() {
    this.filters = {};
    // this.selectedView = "open";
    this.selectedState = [];
    this.selectedContract = [];
    this.selectedProduct = [];
    this.selectedFeature = [];
    this.selectedPriority = [];
    this.selectedCategory = [];
    this.selectedSprint = [];
    this.selectedSolver = [];
    this.selectedContact = [];
    // this.waitingreason = -1;
    this.searchItems(this.searchText.el.value);

    // // this.changeRef.detectChanges();
  }



  reloadDataNew() {
    this.incidents = [];

    if (this.selectedView == "open") {
      this.activeLoaded = false;
      this.incidentsBackup = this.incidentsBackup.filter(x => x.Attributes.statuscode.Value == 2);
    }
    else if (this.selectedView == "closed") {
      this.inactiveLoaded = false;
      this.incidentsBackup = this.incidentsBackup.filter(x => x.Attributes.statuscode.Value == 1);
    }
    else if (this.selectedView == "all") {
      this.activeLoaded = false;
      this.inactiveLoaded = false;

      this.incidentsBackup = [];
    }

    this.supportwebws.getNotificationsForContact(this.authService.privileges.ContactId).subscribe(result => {

      this.facadeService.helperService.notifications = result;
      this.loadDataNew();
  
    });


  }

  loadDataNew() {
    if (this.selectedView == "open") {
      if (!this.activeLoaded) {
        this.loadByState(0);
        this.activeLoaded = true;
      }
      else {
        this.incidents = this.incidentsBackup.filter(x => x.Attributes.statuscode.Value == 1);
        this.getFilters();
      }
    }
    else if (this.selectedView == "closed") {
      if (!this.inactiveLoaded) {
        this.loadByState(1);
        this.inactiveLoaded = true;
        this.incidents = this.incidentsBackup.filter(x => x.Attributes.statuscode.Value == 2);
      }
      else {
        this.incidents = this.incidentsBackup.filter(x => x.Attributes.statuscode.Value == 2);
        this.getFilters();
      }
    }
    else if (this.selectedView == "all") {
      if (!this.activeLoaded && !this.inactiveLoaded) {
        this.loadData();
        this.activeLoaded = true;
        this.inactiveLoaded = true;
      }
      else if (!this.activeLoaded) {
        this.loadByState(0);
        this.activeLoaded = true;
      }
      else if (!this.inactiveLoaded) {
        this.loadByState(1);
        this.inactiveLoaded = true;
      }
      else {
        this.incidents = this.incidentsBackup;
        this.getFilters();
      }
    }
  }


  loadByState(state) {

    this.searching = true;
    this.supportwebws.getIncidentsByState(state).subscribe(result => {
      this.searching = false;
      if (state == 0) {
        if (!this.inactiveLoaded) {
          this.incidents = result;
          this.incidentsBackup = result;
        }
        else {
          this.incidents = this.incidentsBackup.concat(result).sort((a, b) => {
            if (a.Attributes.createdon > b.Attributes.createdon) return -1;
            else return 1;
          });
          this.incidentsBackup = this.incidentsBackup.concat(result).sort((a, b) => {
            if (a.Attributes.createdon > b.Attributes.createdon) return -1;
            else return 1;
          });

        }

        this.resetFilters();

        this.getFilters();
        this.searchItems(this.searchText.el.value);
      }
      else {

        if (!this.activeLoaded) {
          this.incidents = result;
          this.incidentsBackup = result;
        }
        else {
          this.incidentsBackup = this.incidentsBackup.concat(result).sort((a, b) => {
            if (a.Attributes.createdon > b.Attributes.createdon) return -1;
            else return 1;
          });
          this.incidents = this.incidents.concat(result).sort((a, b) => {
            if (a.Attributes.createdon > b.Attributes.createdon) return -1;
            else return 1;
          });
        }
        this.resetFilters();
        this.getFilters();
        this.searchItems(this.searchText.el.value);
      }
    },
      error => {

        this.searching = false;
        this.facadeService.showMessage("Error", "", error.error.Message);
      });
  }

  getFilters() {
    this.contracts = Array.from(new Set(this.incidents.map(incident => incident.FormattedValues.ebs_contractnoid)));
    this.contracts = this.contracts.sort((a, b) => (a < b ? -1 : 1));
    // this.contracts = ["---", ...this.contracts];

    this.products = Array.from(new Set(this.incidents.map(incident => incident.FormattedValues.ebs_productcategortyid)));
    this.products = this.products.sort((a, b) => (a < b ? -1 : 1));
    // this.products = ["---", ...this.products];

    this.features = Array.from(new Set(this.incidents.map(incident => incident.FormattedValues.ebs_devopsfeatureid)));
    this.features = this.features.sort((a, b) => (a < b ? -1 : 1));
    // this.features = ["---", ...this.features];

    this.priorities = Array.from(new Set(this.incidents.map(incident => incident.FormattedValues.ebs_incidentpriorityid)));
    this.priorities = this.priorities.sort((a, b) => (a < b ? -1 : 1));
    // this.priorities = ["---", ...this.priorities];

    this.categories = Array.from(new Set(this.incidents.map(incident => incident.FormattedValues.ebs_incidentcategoryid)));
    this.categories = this.categories.sort((a, b) => (a < b ? -1 : 1));
    // this.categories = ["---", ...this.categories];

    this.solvers = Array.from(new Set(this.incidents.map(incident => incident.FormattedValues.ebs_solverid)));
    this.solvers = this.solvers.sort((a, b) => (a < b ? -1 : 1));
    // this.solvers = ["---", ...this.solvers];

    this.contacts = Array.from(new Set(this.incidents.map(incident => incident.FormattedValues.ebs_contactid)));
    this.contacts = this.contacts.sort((a, b) => (a < b ? -1 : 1));
    // this.contacts = ["---", ...this.contacts];

    this.sprints = Array.from(new Set(this.incidents.map(incident => incident.Attributes.ebs_devopssprintno)));
    this.sprints = this.sprints.sort((a, b) => (a > b ? -1 : 1));
    // this.sprints = ["---", ...this.sprints];
  }

  loadData() {
    this.searching = true;
    this.supportwebws.getIncidents().subscribe(result => {
      this.searching = false;
      this.incidents = result;
      this.incidentsBackup = result;
      // this.incidents = this.incidentsBackup.filter(x => x.Attributes.statuscode.Value == 1);

      this.getFilters();
      this.resetFilters();
      this.searchItems(this.searchText.el.value);
      this.changeRef.detectChanges();
    },
      error => {

        this.searching = false;
        this.facadeService.showMessage("Error", "", error.error.Message);
      });
  }

  onSearchInput() {
    this.searching = true;
  }

  searchItems(ev) {

    this.incidents = this.incidentsBackup;
    this.switchView(this.selectedView);
    let serchText = ev.toLowerCase();
    if (serchText) {
      this.incidents = Array.from(this.incidents).filter(x =>
        ((<any>x).Attributes.ebs_incidentno ? (<any>x).Attributes.ebs_incidentno.toLowerCase() : "").includes(serchText) ||
        ((<any>x).Attributes.ebs_description ? (<any>x).Attributes.ebs_description.toLowerCase() : "").includes(serchText) ||
        ((<any>x).FormattedValues.ebs_contactid ? (<any>x).FormattedValues.ebs_contactid.toLowerCase() : "").includes(serchText) ||
        ((<any>x).FormattedValues.ebs_solverid ? (<any>x).FormattedValues.ebs_solverid.toLowerCase() : "").includes(serchText) ||
        ((<any>x).FormattedValues.ebs_devopsfeatureid ? (<any>x).FormattedValues.ebs_devopsfeatureid.toLowerCase() : "").includes(serchText) ||
        ((<any>x).Attributes.ebs_firstrequestdescription ? this.facadeService.convertToPlain((<any>x).Attributes.ebs_firstrequestdescription).toLowerCase() : "").includes(serchText) ||
        ((<any>x).Attributes.ebs_referenceno ? (<any>x).Attributes.ebs_referenceno.toLowerCase() : "").includes(serchText)
      );
    }

    this.incidents = this.incidents.filter(incident => {
      return Object.entries(this.filters).every(([key, value]) => {

        if (Array.isArray(value)) {
          // Pokud je hodnota filtru pole, kontrolujeme, zda incident obsahuje jednu z hodnot
          return value.includes(incident.FormattedValues[key]) || value.includes(incident.Attributes[key]) || (value.includes('') && !incident.FormattedValues[key] && !incident.Attributes[key]);
        } else if (value !== null && value !== undefined) {
          // Pokud je hodnota filtru definována, porovnáváme přímo
          return incident.FormattedValues[key] === value || incident.Attributes[key] === value;
        }
        // Pokud je hodnota filtru null nebo undefined, ignorujeme tento filtr
        return true;
      });
    });

    if (this.waitingreason == 0)
      this.incidents = this.incidents.filter(x => x.Attributes.ebs_waitingreasoncode ? true : false);
    else if (this.waitingreason != -1 && this.waitingreason != -2)
      this.incidents = this.incidents.filter(x => x.Attributes.ebs_waitingreasoncode ? x.Attributes.ebs_waitingreasoncode.Value == this.waitingreason : false);
    else if (this.waitingreason == -2) {
        this.incidents = this.incidents.filter(x => {
          if (this.hasNotification(x))
            return x;
        });
      }

  }

  itemTapped(event, incident) {
    this.router.navigate(["incident/", incident.Id]);
  }

  itemClicked(event, incident) {
    if (this.lastClickedIncident != incident.Attributes.ebs_incidentno)
      this.lastClickedIncident = incident.Attributes.ebs_incidentno;
    else
      this.lastClickedIncident = null;

    event.stopPropagation();
  }

  expandAll() {
    this.expand = !this.expand;
    this.incidentListCookies.expand = this.expand;
    this.storage.set("incidentlistcookies", this.incidentListCookies);
  }

  sortByPriority() {
    if (this.descendingPriority) {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_incidentpriorityid)
          return 1

        if (!b.FormattedValues.ebs_incidentpriorityid)
          return -1

        if (a.FormattedValues.ebs_incidentpriorityid > b.FormattedValues.ebs_incidentpriorityid) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_incidentpriorityid)
          return -1

        if (!b.FormattedValues.ebs_incidentpriorityid)
          return 1

        if (a.FormattedValues.ebs_incidentpriorityid < b.FormattedValues.ebs_incidentpriorityid) return -1;
        else return 1;
      });
    }

    this.descendingPriority = !this.descendingPriority;
  }

  sortByCategory() {
    if (this.descendingCategory) {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_incidentcategoryid)
          return 1

        if (!b.FormattedValues.ebs_incidentcategoryid)
          return -1

        if (a.FormattedValues.ebs_incidentcategoryid > b.FormattedValues.ebs_incidentcategoryid) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_incidentcategoryid)
          return -1

        if (!b.FormattedValues.ebs_incidentcategoryid)
          return 1

        if (a.FormattedValues.ebs_incidentcategoryid < b.FormattedValues.ebs_incidentcategoryid) return -1;
        else return 1;
      });
    }

    this.descendingCategory = !this.descendingCategory;
  }

  sortBySprint() {
    if (this.descendingSprint) {
      this.incidents.sort((a, b) => {
        if (!a.Attributes.ebs_devopssprintno)
          return 1

        if (!b.Attributes.ebs_devopssprintno)
          return -1

        if (a.Attributes.ebs_devopssprintno > b.Attributes.ebs_devopssprintno) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.Attributes.ebs_devopssprintno)
          return -1

        if (!b.Attributes.ebs_devopssprintno)
          return 1

        if (a.Attributes.ebs_devopssprintno < b.Attributes.ebs_devopssprintno) return -1;
        else return 1;
      });
    }

    this.descendingSprint = !this.descendingSprint;
  }

  sortByFeature() {
    if (this.descendingFeature) {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_devopsfeatureid)
          return 1

        if (!b.FormattedValues.ebs_devopsfeatureid)
          return -1

        if (a.FormattedValues.ebs_devopsfeatureid > b.FormattedValues.ebs_devopsfeatureid) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_devopsfeatureid)
          return -1

        if (!b.FormattedValues.ebs_devopsfeatureid)
          return 1

        if (a.FormattedValues.ebs_devopsfeatureid < b.FormattedValues.ebs_devopsfeatureid) return -1;
        else return 1;
      });
    }

    this.descendingFeature = !this.descendingFeature;
  }

  sortByState() {
    if (this.descendingState) {
      this.incidents.sort((a, b) => {
        if (!a.Attributes.ebs_bpfstatus)
          return 1

        if (!b.Attributes.ebs_bpfstatus)
          return -1

        if (a.Attributes.ebs_bpfstatus > b.Attributes.ebs_bpfstatus) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.Attributes.ebs_bpfstatus)
          return -1

        if (!b.Attributes.ebs_bpfstatus)
          return 1

        if (a.Attributes.ebs_bpfstatus < b.Attributes.ebs_bpfstatus) return -1;
        else return 1;
      });
    }

    this.descendingState = !this.descendingState;
  }

  sortByContract() {
    if (this.descendingContract) {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_contractnoid)
          return 1

        if (!b.FormattedValues.ebs_contractnoid)
          return -1

        if (a.FormattedValues.ebs_contractnoid > b.FormattedValues.ebs_contractnoid) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_contractnoid)
          return -1

        if (!b.FormattedValues.ebs_contractnoid)
          return 1

        if (a.FormattedValues.ebs_contractnoid < b.FormattedValues.ebs_contractnoid) return -1;
        else return 1;
      });
    }

    this.descendingContract = !this.descendingContract;
  }

  sortByProduct() {
    if (this.descendingProduct) {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_productcategortyid)
          return 1

        if (!b.FormattedValues.ebs_productcategortyid)
          return -1

        if (a.FormattedValues.ebs_productcategortyid > b.FormattedValues.ebs_productcategortyid) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_productcategortyid)
          return -1

        if (!b.FormattedValues.ebs_productcategortyid)
          return 1

        if (a.FormattedValues.ebs_productcategortyid < b.FormattedValues.ebs_productcategortyid) return -1;
        else return 1;
      });
    }

    this.descendingProduct = !this.descendingProduct;
  }

  sortByContact() {
    if (this.descendingContact) {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_contactid)
          return 1

        if (!b.FormattedValues.ebs_contactid)
          return -1

        if (a.FormattedValues.ebs_contactid > b.FormattedValues.ebs_contactid) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_contactid)
          return -1

        if (!b.FormattedValues.ebs_contactid)
          return 1

        if (a.FormattedValues.ebs_contactid < b.FormattedValues.ebs_contactid) return -1;
        else return 1;
      });
    }

    this.descendingContact = !this.descendingContact;
  }

  sortBySolver() {
    if (this.descendingSolver) {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_solverid)
          return 1

        if (!b.FormattedValues.ebs_solverid)
          return -1

        if (a.FormattedValues.ebs_solverid > b.FormattedValues.ebs_solverid) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (!a.FormattedValues.ebs_solverid)
          return -1

        if (!b.FormattedValues.ebs_solverid)
          return 1

        if (a.FormattedValues.ebs_solverid < b.FormattedValues.ebs_solverid) return -1;
        else return 1;
      });
    }

    this.descendingSolver = !this.descendingSolver;
  }

  sortByCreatedOn() {
    if (this.descendingCreatedOn) {
      this.incidents.sort((a, b) => {
        if (a.Attributes.createdon > b.Attributes.createdon) return -1;
        else return 1;
      });
    } else {
      this.incidents.sort((a, b) => {
        if (a.Attributes.createdon < b.Attributes.createdon) return -1;
        else return 1;
      });
    }

    this.descendingCreatedOn = !this.descendingCreatedOn;
  }

  changeView($event) {

    // this.switchView($event.detail.value);
    this.selectedView = $event.detail.value;
    this.searchItems(this.searchText.el.value);
  }

  setWaitingreason($event) {

    debugger;
    this.waitingreason = +$event.detail.value;
    if (this.waitingreason == 866980002 && this.authService.privileges.ApproveSolutionTime != 866980002 ||
      this.waitingreason == 866980003 && this.authService.privileges.ApprovePublishingToLive != 866980002 ||
      this.waitingreason == 866980004 && this.authService.privileges.ApproveInvoicing != 866980002) {

      let userName = this.authService.privileges.ContactName;

      this.filters.ebs_contactid = userName;
      this.selectedContact = [userName];


      // if (!this.username && this.authService.privileges && this.incidents) {
      //   
      //   let incidentWithName = this.incidentsBackup.find(x => {
      //     return x.Attributes.ebs_contactid && x.Attributes.ebs_contactid.Id == this.authService.privileges.ContactId
      //   });

      //   this.username = incidentWithName ? incidentWithName.FormattedValues.ebs_contactid : null;
      // }

      // if (this.username) {
      //   this.filters.ebs_contactid = this.username;
      //   this.selectedContact = [this.username];
      // }
    }
    else {
      delete this.filters.ebs_contactid;
      this.selectedContact = [];
    }

    this.searchItems(this.searchText.el.value);
    

  }

  switchView(selectedView) {
    this.loadDataNew();
    // switch (selectedView) {
    //   case "open":
    //     this.incidents = this.incidentsBackup.filter(x => x.Attributes.statuscode.Value == 1);
    //     break;
    //   case "closed":
    //     this.incidents = this.incidentsBackup.filter(x => x.Attributes.statuscode.Value == 2);
    //     break;
    //   case "all":
    //     this.incidents = this.incidentsBackup;
    //     break;
    // }
  }

  filterByState($event) {

    this.selectedState = $event.detail.value;
    if (this.selectedState.length > 0)
      this.filters.ebs_bpfstatus = this.selectedState;
    else
      delete this.filters.ebs_bpfstatus;
    this.searchItems(this.searchText.el.value);
  }

  filterByContract($event) {

    this.selectedContract = $event.detail.value;
    if (this.selectedContract.length > 0)
      this.filters.ebs_contractnoid = this.selectedContract;
    else
      delete this.filters.ebs_contractnoid;
    this.searchItems(this.searchText.el.value);
  }

  filterByProduct($event) {

    this.selectedProduct = $event.detail.value;
    if (this.selectedProduct.length > 0)
      this.filters.ebs_productcategortyid = this.selectedProduct;
    else
      delete this.filters.ebs_productcategortyid;
    this.searchItems(this.searchText.el.value);
  }

  filterByFeature($event) {

    this.selectedFeature = $event.detail.value;
    if (this.selectedFeature.length > 0)
      this.filters.ebs_devopsfeatureid = this.selectedFeature;
    else
      delete this.filters.ebs_devopsfeatureid;
    this.searchItems(this.searchText.el.value);
  }

  filterByPriority($event) {

    this.selectedPriority = $event.detail.value;
    if (this.selectedPriority.length > 0)
      this.filters.ebs_incidentpriorityid = this.selectedPriority;
    else
      delete this.filters.ebs_incidentpriorityid;
    this.searchItems(this.searchText.el.value);
  }

  filterByCategory($event) {

    this.selectedCategory = $event.detail.value;
    if (this.selectedCategory.length > 0)
      this.filters.ebs_incidentcategoryid = this.selectedCategory;
    else
      delete this.filters.ebs_incidentcategoryid;
    this.searchItems(this.searchText.el.value);
  }

  filterBySprint($event) {

    this.selectedSprint = $event.detail.value;
    if (this.selectedSprint.length > 0)
      this.filters.ebs_devopssprintno = this.selectedSprint;
    else
      delete this.filters.ebs_devopssprintno;
    this.searchItems(this.searchText.el.value);
  }

  filterBySolver($event) {

    this.selectedSolver = $event.detail.value;
    if (this.selectedSolver.length > 0)
      this.filters.ebs_solverid = this.selectedSolver;
    else
      delete this.filters.ebs_solverid;
    this.searchItems(this.searchText.el.value);
  }

  filterByContact($event) {

    this.selectedContact = $event.detail.value;
    if (this.selectedContact.length > 0)
      this.filters.ebs_contactid = this.selectedContact;
    else
      delete this.filters.ebs_contactid;
    this.searchItems(this.searchText.el.value);
  }

  showRow(incidentNo) {
    return incidentNo == this.lastClickedIncident || this.expand;
  }

  stringToBoolean(string) {
    if (!string)
      return false;

    switch (string.toLowerCase().trim()) {
      case "true": case "yes": case "ano": case "1": return true;
      case "false": case "no": case "ne": case "0": case null: return false;
      default: return Boolean(string);
    }
  }

  calculateTimeSpent(i) {
    if (!i.Attributes.ebs_invoicingmethode)
      return "";

    if (i.Attributes.ebs_invoicingmethode.Value == 866980000) // 866980000 straveny cas ### 866,980,001 odsouhlaseny cas
      return i.Attributes.ebs_timespent ? +i.Attributes.ebs_timespent / 60 : "";

    if (i.Attributes.ebs_invoicingmethode.Value == 866980001)
      return i.Attributes.ebs_acceptedsolutiontime ? +i.Attributes.ebs_acceptedsolutiontime / 60 : "";
  }

  exportexcel(): void {

    var incidentsToExport: [];
    const datepipe: DatePipe = new DatePipe('cs-CZ');
    incidentsToExport = this.incidents.map(i => {
      let createdon = datepipe.transform(i.Attributes.createdon, 'dd.MM.yyyy');
      let ebs_estimatedsolutiontimeacceptedon = datepipe.transform(i.Attributes.ebs_estimatedsolutiontimeacceptedon, 'dd.MM.yyyy');
      let ebs_promiseddeliverydate = datepipe.transform(i.Attributes.ebs_promiseddeliverydate, 'dd.MM.yyyy');
      let ebs_requireddeliverydate = datepipe.transform(i.Attributes.ebs_requireddeliverydate, 'dd.MM.yyyy');
      let ebs_publishedtoliveon = datepipe.transform(i.Attributes.ebs_publishedtoliveon, 'dd.MM.yyyy');
      let ebs_invoicedon = datepipe.transform(i.Attributes.ebs_invoicedon, 'dd.MM.yyyy');
      let timeSpent = this.calculateTimeSpent(i);

      var container = {
        "Číslo incidentu": i.Attributes.ebs_incidentno,
        "Referenční číslo": i.Attributes.ebs_referenceno,
        "Popis": i.Attributes.ebs_description,
        // "Stav incidentu": 
        "Firma": i.FormattedValues.ebs_companyid,
        "Zadavatel": i.FormattedValues.ebs_contactid,
        "Vytvořeno": createdon,
        "Kategorie produktu": i.FormattedValues.ebs_productcategortyid,
        "Priorita": i.FormattedValues.ebs_incidentpriorityid,
        "Kategorie incidentu": i.FormattedValues.ebs_incidentcategoryid,
        "Fakturovatelné": i.Attributes.ebs_invoicable ? "ano" : "ne",
        // "Způsob fakturace": i.FormattedValues.ebs_invoicingmethode,
        "Řešitel": i.FormattedValues.ebs_solverid,
        "Aktivní": i.Attributes.statuscode.Value == 1 ? "ano" : "ne",
        "Požadované datum dodání": ebs_requireddeliverydate,
        "Slíbené datum dodání": ebs_promiseddeliverydate,
        "Odhadovaná doba řešení": i.Attributes.ebs_estimatedsolutiontime ? +i.Attributes.ebs_estimatedsolutiontime / 60 : "",
        "Akceptovaná doba řešení": i.Attributes.ebs_acceptedsolutiontime ? +i.Attributes.ebs_acceptedsolutiontime / 60 : "",
        "Strávený čas": timeSpent,
        "Datum akceptace odhadované doby řešení": ebs_estimatedsolutiontimeacceptedon,
        // "Nasazeno k testu u zákazníka dne": i.FormattedValues.ebs_publishedtoliveon,
        "Nasazení do živé schváleno": i.Attributes.ebs_approvedforlivepublishing ? "ano" : "ne",
        "Nasazeno do živé dne": ebs_publishedtoliveon,
        "Schváleno k fakturaci": i.Attributes.ebs_approvedforinvoicing ? "ano" : "ne",
        "Fakturaci schválil": i.FormattedValues.ebs_invoicingapprovedby,
        "Fakturováno": i.Attributes.ebs_invoiced ? "ano" : "ne",
        "Fakturováno dne": ebs_invoicedon,
        "Čeká na reakci zákazníka": i.Attributes.ebs_waitingforcustomerresponse ? "ano" : "ne",
        "Důvod čekání": i.FormattedValues.ebs_waitingreasoncode
      };

      return container;
      //return i.FormattedValues
    })
    this.facadeService.exportexcel(incidentsToExport, "incidents");
  }

  toggleFilters() {

    if (!this.filtersShown)
      this.openFilters();
    else
      this.closeFilters();

    this.incidentListCookies.filtersShown = this.filtersShown;
    this.storage.set("incidentlistcookies", this.incidentListCookies);
  }

  openFilters() {
    this.filtersShown = true;
    document.getElementById("mySidenav").style.width = "300px";
    document.getElementById("main").style.marginLeft = "300px";
    // document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }

  closeFilters() {
    this.filtersShown = false;
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    // document.body.style.backgroundColor = "white";
  }

  approve(event, incident) {

    this.approvingOrRejecting = true;

    this.approvingIncident = incident.Attributes.ebs_incidentno;
    let index = this.incidents.findIndex(x => x.Id == incident.Id);

    let approvalText;
    let type;

    switch (this.waitingreason) {
      case 866980002:
        type = 866980000;
        break;
      case 866980003:
        type = 866980001;
        break;
      case 866980004:
        type = 866980002;
        break;
    }

    if (type == 866980000) {
      approvalText = "odhadovaný čas řešení";
    }
    else if (type == 866980001) {
      approvalText = "nasazení do živé DB";
    }
    else if (type == 866980002) {
      approvalText = "fakturaci";
    }

    this.facadeService.presentAlertConfirm("Odsouhlasit", `Opravdu si přejete odsouhlasit ${approvalText}?`,
      () => {
        this.loading = true;
        this.facadeService.supportwebwsService.approve(incident.Id, type, incident.Attributes.ebs_estimatedsolutiontime, true).subscribe((result) => {

          if (type == 866980000) {
            // this.solutionTimeAccepted = result;
            this.incidents[index].Attributes.ebs_estimatedsolutiontimeaccepted = result;
            this.incidents[index].FormattedValues.ebs_estimatedsolutiontimeaccepted = result.toString();
          }
          else if (type == 866980001) {
            // this.publishingToLiveApproved = result;
            this.incidents[index].Attributes.ebs_approvedforlivepublishing = result;
            this.incidents[index].FormattedValues.ebs_approvedforlivepublishing = result.toString()
          }
          else if (type == 866980002) {

            // this.invoicingApproved = result;
            this.incidents[index].Attributes.ebs_approvedforinvoicing = result;
            this.incidents[index].FormattedValues.ebs_approvedforinvoicing = result.toString()
          }

          if (result == true) {
            this.incidents[index].Attributes.ebs_waitingforcustomerresponse = false;
            this.incidents[index].Attributes.ebs_waitingreasoncode = null;
            this.incidents[index].FormattedValues.ebs_waitingforcustomerresponse = "false";
            this.incidents[index].FormattedValues.ebs_waitingreasoncode = null;

            this.incidents.splice(index, 1);
          }
          this.loading = false;

        }, error => {
          this.loading = false;
        });
      }, () => { this.loading = false });

    event.stopPropagation();
  }

  reject(event, incident) {

    this.approvingOrRejecting = false;

    let approvalText;
    let rejectionText;

    this.approvingIncident = incident.Attributes.ebs_incidentno;
    let index = this.incidents.findIndex(x => x.Id == incident.Id);
    let rejectedIncident = this.incidents[index];

    let type;

    switch (this.waitingreason) {
      case 866980002:
        type = 866980000;
        break;
      case 866980003:
        type = 866980001;
        break;
      case 866980004:
        type = 866980002;
        break;
    }

    if (type == 866980000) {
      approvalText = "odhadovaný čas řešení";
      rejectionText = "- Zamítnutí odhadovaného času řešení ";
    }
    else if (type == 866980001) {
      approvalText = "nasazení do živé DB";
      rejectionText = "- Zamítnutí nasazení do živé DB ";
    }
    else if (type == 866980002) {
      approvalText = "fakturaci";
      rejectionText = "- Zamítnutí fakturace ";
    }

    this.facadeService.presentAlertConfirm("Zamítnout", `Opravdu si přejete zamítnout ${approvalText}?`,
      () => {
        this.loading = true;
        this.facadeService.supportwebwsService.approve(incident.Id, type, incident.Attributes.ebs_estimatedsolutiontime, false).subscribe((result) => {


          if (result == true) {
            this.incidents[index].FormattedValues.ebs_waitingforcustomerresponse = "false";
            this.incidents[index].FormattedValues.ebs_waitingreasoncode = null;
            this.incidents[index].Attributes.ebs_waitingforcustomerresponse = false;
            this.incidents[index].Attributes.ebs_waitingreasoncode = null;

            this.incidents.splice(index, 1);
          }
          this.loading = false;
          this.rejectSupportRequest(rejectionText, rejectedIncident);

        }, error => {
          this.loading = false;
        });
      }, () => { this.loading = false; });

    event.stopPropagation();
  }

  canapprove(incident) {

    if (!this.authService.privileges)
      return false;

    let p = this.authService.privileges;
    let ApproveSolutionTime = false;
    let ApprovePublishingToLive = false;
    let ApproveInvoicing = false;

    if (p.ApproveSolutionTime == 866980000)
      ApproveSolutionTime = false;
    else if (p.ApproveSolutionTime == 866980001 && p.ContactId != incident.Attributes.ebs_contactid.Id)
      ApproveSolutionTime = false;
    else
      ApproveSolutionTime = true;

    if (p.ApprovePublishingToLive == 866980000)
      ApprovePublishingToLive = false;
    else if (p.ApprovePublishingToLive == 866980001 && p.ContactId != incident.Attributes.ebs_contactid.Id)
      ApprovePublishingToLive = false;
    else
      ApprovePublishingToLive = true;

    if (p.ApproveInvoicing == 866980000)
      ApproveInvoicing = false;
    else if (p.ApproveInvoicing == 866980001 && p.ContactId != incident.Attributes.ebs_contactid.Id)
      ApproveInvoicing = false;
    else
      ApproveInvoicing = true;

    switch (this.waitingreason) {
      case 866980002:
        return ApproveSolutionTime;

      case 866980003:
        return ApprovePublishingToLive

      case 866980004:
        return ApproveInvoicing

    }

  }

  rejectSupportRequest(rejectionText, rejectedIncident) {

    this.facadeService.routerParameters = {
      "incident": rejectedIncident,
      "rejectionText": rejectionText
      // "incidentpriorityid": this.incident.Attributes.ebs_incidentpriorityid.Id,
      // "productcategoryid": this.incident.Attributes.ebs_productcategortyid.Id
    }
    this.facadeService.router.navigate(["supportrequest/", "newSupportRequest"])
  }


  hasNotification(incident) {
    if (this.facadeService.helperService.notifications && this.facadeService.helperService.notifications.find(x => {
      if (x.Attributes.ebs_incidentid)
        return x.Attributes.ebs_incidentid.Id == incident.Id;
    }))
      return true;
    else
      return false;
  }

  countOfNotifications(incident) {
    return this.facadeService.helperService.notifications && this.facadeService.helperService.notifications.filter(x => {
      if (x.Attributes.ebs_incidentid)
        return x.Attributes.ebs_incidentid.Id == incident.Id;
    }).length;
  }

  countOfAllNotifications() {
    return this.facadeService.helperService.notifications ? this.facadeService.helperService.notifications.length : 0;
  }

  showrefno() {
    this.showRefNo = !this.showRefNo;
  }
}

