import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/services/facade.service';

@Component({
  selector: 'app-licencecontractlist',
  templateUrl: './licencecontractlist.component.html',
  styleUrls: ['./licencecontractlist.component.scss'],
})
export class LicencecontractlistComponent implements OnInit {

  licenceContracts: any;
  licenceContractsBackup: any;
  searching: any = false;
  selectedView: any = "active";

  constructor(private router: Router, private facadeService: FacadeService) { }

  ngOnInit() {
    this.searching = true;
    this.facadeService.menuIndexSelected = 3;
    this.facadeService.supportwebwsService.getLicenceContracts().subscribe(result => {
      this.searching = false;
      this.licenceContracts = result.filter(x => x.Attributes.statecode.Value == 0);
      this.licenceContractsBackup = result;
    });
  }

  itemTapped(event, incident) {
    this.router.navigate(["licencecontract/", incident.Id]);
  }

  changeView($event) {
    debugger;
    this.selectedView = $event.detail.value;

    if (this.selectedView == "all") {
      this.licenceContracts = this.licenceContractsBackup;
      return;
    }
    if (this.selectedView == "active") {
      this.licenceContracts = this.licenceContractsBackup.filter(x => x.Attributes.statecode.Value == 0);
      return
    }
    if (this.selectedView == "closed") {
      this.licenceContracts = this.licenceContractsBackup.filter(x => x.Attributes.statecode.Value == 1);
      return
    }
    
  }
}
