import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { FacadeService } from 'src/app/services/facade.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-licencecontract',
  templateUrl: './licencecontract.component.html',
  styleUrls: ['./licencecontract.component.scss'],
})
export class LicencecontractComponent implements OnInit {

  showFields1: boolean = true;

  id: any;
  newquantity: number;
  licenceContract: any = {
    Attributes: {},
    FormattedValues: {},
  };
  licenceContractLines: any[] = [
    {
      Attributes: {},
      FormattedValues: {},
    }
  ];

  parentLicenceContractLines: any[] = [
    {
      Attributes: {},
      FormattedValues: {}
    }
  ];

  searching: boolean = false;
  lines: any;
  linesBackup: any;

  selectedView: any = "active";
  licenceContractPageCookies: any;

  constructor(private route: ActivatedRoute, private facadeService: FacadeService, private storage: Storage) { }

  ngOnInit() {
    this.storage.get("licencecontractpagecookies").then(x => {
      if(x) {
        this.licenceContractPageCookies = x;
        this.showFields1 = x.showFields1;
      }
      else {
        this.showFields1 = true;
        this.licenceContractPageCookies = {
          showFields1: true,
        }
      }      
    });

    this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      this.facadeService.supportwebwsService.getLicenceContract(this.id).subscribe(result => {
        debugger;
        this.licenceContract = result;
      });

    });
  }


  ionViewWillEnter() {
    this.searching = true;
    this.facadeService.supportwebwsService.getLicenceContractLines(this.id).subscribe(result => {
      debugger;
      this.licenceContractLines = result;
      this.parentLicenceContractLines = result.filter(x => x.Attributes.ebs_parentcontractline == null);

      this.licenceContractLines.forEach(lcl => {

        this.facadeService.supportwebwsService.getSellingPricesToLicenceContractLine(lcl.Id).subscribe(sp => {
          var prices = sp.filter(x => x.Attributes.ebs_quantity <= lcl.Attributes.ebs_quantity);
          prices = prices.filter(x => x.Attributes.ebs_sellingpriceperquantity.Value === Math.min(...prices.map(f => f.Attributes.ebs_sellingpriceperquantity.Value)));

          lcl.actualPricePerOne = prices.length > 0 ? prices[0].FormattedValues.ebs_sellingpriceperquantity : (lcl.FormattedValues.ebs_sellingpriceperonepiece ? lcl.FormattedValues.ebs_sellingpriceperonepiece : 0);
        });

        debugger;
        if(!lcl.Attributes.ebs_parentcontractline) {
          let parent = this.parentLicenceContractLines.find(p => p.Id == lcl.Id);
          if(!parent.ChildLines)
            parent.ChildLines = [];
          parent.ChildLines.push(lcl);
        }
        else {
          let parent = this.parentLicenceContractLines.find(p => p.Id == lcl.Attributes.ebs_parentcontractline.Id);
          if(!parent.ChildLines)
            parent.ChildLines = [];
          parent.ChildLines.push(lcl);
        }
      });

      // this.licenceContractLines.forEach(element => {
      this.parentLicenceContractLines.forEach(element => {

          if(element.ChildLines.length < 2)
            element.ChildLines = null;

          this.facadeService.supportwebwsService.getLicenceRequestsForLine(element.Attributes.ebs_licencecontractlineid).subscribe(result => {
          debugger;
          element.Requests = result.filter(x => x.Attributes.statecode.Value == 0);
        });

        this.facadeService.supportwebwsService.getSellingPricesToLicenceContractLine(element.Attributes.ebs_licencecontractlineid).subscribe(result => {
          debugger;
          element.SellingPrices = result;

          var prices = element.SellingPrices.filter(x => x.Attributes.ebs_quantity <= element.Attributes.ebs_quantity);
          prices = prices.filter(x => x.Attributes.ebs_sellingpriceperquantity.Value === Math.min(...prices.map(f => f.Attributes.ebs_sellingpriceperquantity.Value)));
          element.Attributes.ebs_sellingpriceperonepiece = { Value:  prices.length > 0 ? prices[0].Attributes.ebs_sellingpriceperquantity.Value : element.Attributes.ebs_sellingpriceperonepiece.Value };
          element.FormattedValues.ebs_sellingpriceperonepiece = prices.length > 0 ? prices[0].FormattedValues.ebs_sellingpriceperquantity : element.FormattedValues.ebs_sellingpriceperonepiece;
        });
      });

      this.lines = this.parentLicenceContractLines.filter(x => x.Attributes.statecode.Value == 0);
      this.linesBackup = this.parentLicenceContractLines;

      this.searching = false;

      this.filter();
    });
  }

  formatPrice(price, currency) {
    debugger
    switch (currency) {
      case "CZK":
        return `${price} Kč`;

      case "USD":
        return `$${price}`;

      case "EUR":
        return `€${price}`;

    }
  }

  formatCzk(priceText) {
    debugger;
    if(priceText.startsWith("Kč")) {
      priceText = priceText.substring(2, priceText.length) + " Kč";
    }
  }

  itemTapped(line: any) {
    debugger;
    // if (line.Requests.length > 0) {
    //   this.facadeService.showMessage("Chyba", "", "Nelze změnit počet lincencí, protože již probíhá změnové řízení.").then(() => {
    //   });
    //   return;
    // }

    // this.facadeService.createLicenceRequest(line).then(x => {
    //   debugger;
    //   //this.loadContractLines();
    // },
    //   error => {
    //     debugger;
    //     this.facadeService.showMessage("Error", "", error.error.Message);
    //   });

    this.facadeService.router.navigate(["licencerequest/", line.Attributes.ebs_licencecontractlineid])

  }

  changeView($event) {
    debugger;
    this.selectedView = $event.detail.value; 
    
    this.filter();
  }

  filter() {
    if (this.selectedView == "all") {
      this.lines = this.linesBackup;
      return;
    }
    if (this.selectedView == "active") {
      this.lines = this.linesBackup.filter(x => x.Attributes.statecode.Value == 0);
      return
    }
    if (this.selectedView == "closed") {
      this.lines = this.linesBackup.filter(x => x.Attributes.statecode.Value == 1);
      return
    }
  }

  showFields1Fce() {
    this.showFields1 = !this.showFields1;
    this.licenceContractPageCookies.showFields1 = this.showFields1;
    this.storage.set("licencecontractpagecookies", this.licenceContractPageCookies);
  }

}
